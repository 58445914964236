import clsx from "clsx";
import React, { useEffect, useState } from "react";

const Hamburger: React.FC<{
  children: React.ReactNode;
  transparent?: "all" | "mobile";
}> = ({ children, transparent }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!transparent) return;
    const scrolled = window.scrollY > 100;
    if (scrolled) return;
    const header = document.querySelector(".header");
    const text = document.querySelectorAll(".scroll-text-white-black");
    const body = document.body;
    const logo = document.querySelector(".logo");

    if (expanded) {
      header?.classList.add("bg-white");
      header?.classList.remove("bg-transparent");
      body.classList.add("overflow-hidden"); // Prevent scrolling
      text.forEach((t) => {
        t.classList.add("text-black");
        t.classList.remove("text-white");
      });
      logo?.classList.remove("brightness-0");
      logo?.classList.remove("invert");
    } else {
      header?.classList.add("bg-transparent");
      header?.classList.remove("bg-white");
      body.classList.remove("overflow-hidden"); // Allow scrolling
      text.forEach((t) => {
        t.classList.remove("text-black");
        t.classList.add("text-white");
      });
      logo?.classList.add("brightness-0");
      logo?.classList.add("invert");
    }
  }, [expanded]);

  return (
    <>
      <button
        className="flex justify-center"
        onClick={() => setExpanded(!expanded)}
        aria-label="Menu"
      >
        <div className="h-3 w-7">
          <div
            className={clsx(
              "scroll-bg-white-black h-0.5 transition duration-200",
              expanded
                ? "translate-y-1 rotate-45 bg-black"
                : transparent
                  ? "bg-white"
                  : "bg-black",
            )}
          ></div>
          <div
            className={clsx(
              "scroll-bg-white-black mt-1.5 h-0.5 transition duration-200",
              expanded
                ? "-translate-y-1 -rotate-45 bg-black"
                : transparent
                  ? "bg-white"
                  : "bg-black",
            )}
          ></div>
        </div>
      </button>
      {expanded && (
        <div className="fixed left-0 top-0 mt-20 flex h-screen w-screen animate-roll flex-col divide-y bg-white">
          <div className="h-0.1 bg-gray-300"></div> {/* Top Divider */}
          <a
            href="/women"
            onClick={() => setExpanded(false)}
            className="flex h-24 w-full items-center justify-center"
          >
            <p className="text-lg uppercase hover:scale-110">Women</p>
          </a>
          <a
            href="/men"
            onClick={() => setExpanded(false)}
            className="flex h-24 w-full items-center justify-center"
          >
            <p className="text-lg uppercase hover:scale-110">Men</p>
          </a>
          <a
            href="/#about"
            onClick={() => setExpanded(false)}
            className="flex h-24 w-full items-center justify-center"
          >
            <p className="text-lg uppercase hover:scale-110">About</p>
          </a>
          <div className="h-0.1 bg-gray-300"></div> {/* Bottom Divider */}
          {children}
        </div>
      )}
    </>
  );
};

export default Hamburger;
